import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Home } from 'react-feather'
import { Layout, SEO, HMOSlider } from '../../components/common'
import { Container, Row, Col, Breadcrumb, Image, Card } from 'react-bootstrap'
import { FacebookShareButton, TwitterShareButton } from 'react-share'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

class ShowSuite extends Component {
  constructor(props) {
    super(props)
    this.state = {
      photoIndex: 0,
      isOpen: false
    }
  }

  handleToggleOverlay = (index) => {
    this.setState({
      isOpen: !this.state.isOpen,
      photoIndex: index
    })
  }

  render() {
    const { photoIndex, isOpen } = this.state
    const { photos, banner, title, description, slug, topNav, accreditedHmos, } = this.props.pageContext
    const { favicon } = topNav
    const maxImageToDisplay = 5
    let images = []
    images.push(banner)
    photos.map(img => images.push(img.avatar))
    const photosCount = images.length

    return (
      <>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
        <SEO title={"Suite - " + title} favicon={favicon}/>
        <Layout activeNav='our-facilities' data={topNav}>
          <Container className="my-4 md-mt-10 sm-mt-7">
            <Breadcrumb className="breadcrumb-wrapper mx-0">
              <Breadcrumb.Item href="/">
                <Home className="text-violet mr-2" size={14} />
                Home
                </Breadcrumb.Item>
              <Breadcrumb.Item href="/our-facilities">
                Facilities
                </Breadcrumb.Item>
              <Breadcrumb.Item active>{title}</Breadcrumb.Item>
            </Breadcrumb>

            <Row>
              <Col lg={4} md={12} sm={12} className="my-3 justify-content-center">
                <Row>
                  {images && photosCount > 0 ? images.map((img, i) => i === 0
                    ? <Col lg={12} className="mb-3 justify-content-center p-0">
                      <Image src={img || 'https://seeyoudoc.s3-ap-southeast-1.amazonaws.com/assets/banner.png'} onClick={() => this.handleToggleOverlay(i)} alt="banner" className="custom-image-xxxl" />
                    </Col>
                    : (i < 4
                      ? <Col lg={3} className="px-1 mb-3 cursor-pointer">
                        <Image src={img} onClick={() => this.handleToggleOverlay(i)} alt="banner" className="w-100 h-100 image-cover mh-81" />
                      </Col>
                      : (i > 4 ? null : <Col lg={3} className="px-1 mb-3 cursor-pointer">
                        {photosCount > 5
                          ? <button onClick={() => this.handleToggleOverlay(i)} className="bg-dark position-absolute w-faded-black h-100 faded-black d-flex justify-content-center align-items-center">
                            <h2 className="text-white text-faded-black">+{photosCount - maxImageToDisplay}</h2>
                          </button>
                          : null}
                        <Image src={img} onClick={() => this.handleToggleOverlay(i)} alt="banner" className="w-100 h-100 image-cover mh-81" />
                      </Col>))) : null}
                </Row>
              </Col>
              <Col lg={8} md={12} sm={12} className="my-3">
                <Card>
                  <Card.Body>
                    <h2 className="text-dark-slate-blue mt-2 mb-1">{title}</h2>
                    <div className="my-4">
                      <div dangerouslySetInnerHTML={{ __html: description }} />
                    </div>
                  </Card.Body>
                  <div className="border-dashed-bottom my-2" />

                  <Card.Body>
                    <h5 className="mb-2">Share this article to</h5>
                    <Row>
                      <Col>
                        <FacebookShareButton
                          url={process.env.MH_URL + "/suite/" + slug}
                          className="btn btn-outline-light btn-lg btn-block d-flex flex-row align-items-center border text-dark"
                        >
                          <Image src={require("../../images/facebook-logo.png")} className="image-h-icon" />
                          <span className="w-100 text-center">
                            Facebook
                            </span>
                        </FacebookShareButton>
                      </Col>
                      <Col>
                        <TwitterShareButton
                          url={process.env.MH_URL + "/suite/" + slug}
                          className="btn btn-outline-light btn-lg btn-block d-flex flex-row align-items-center border text-dark"
                        >
                          <Image src={require("../../images/twitter-logo.png")} className="image-h-icon" />
                          <span className="w-100 text-center">
                            Twitter
                            </span>
                        </TwitterShareButton>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>

          <HMOSlider data={accreditedHmos} />
        </Layout>
      </>
    )
  }
}

ShowSuite.propTypes = {
  pageContext: PropTypes.shape({
    banner: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.any,
    slug: PropTypes.string.isRequired,
    topNav: PropTypes.object.isRequired,
    accreditedHmos: PropTypes.array.isRequired,
  }).isRequired
}

export default ShowSuite
